
















































































































import { AddAdminApi } from "@/includes/Api/AddAdmin.api";
import { errorNotification } from "@/includes/NotificationService";
import {
  AddAdminByLinkRequest,
  AddAdminByLinkResponse,
  FullAddAdminRequest,
} from "@/includes/types/AddAdmin/types";
import { InputSetups } from "@/mixins/input-setups";
import SitePermissions from "@/components/AddAdmin/components/SitePermissions.vue";

import TextInput from 'piramis-base-components/src/components/Pi/fields/TextInput/TextInput.vue';
import PiTabs from "piramis-base-components/src/components/PiTabs.vue";
import { SelectOptionData } from "piramis-base-components/src/components/Pi/types";
import ClipboardCopyMixin from "piramis-base-components/src/logic/helpers/ClipboardCopyMixin";

import { Component, VModel, Mixins, PropSync, Emit, Prop } from 'vue-property-decorator';

enum RequestType {
  Link,
  Name
}

@Component({
  data() {
    return {
      RequestType
    }
  },
  components: {
    SitePermissions,
    TextInput,
    PiTabs
  }
})
export default class AddAdminModal extends Mixins(InputSetups, ClipboardCopyMixin) {
  @VModel() modal!: boolean

  @PropSync('model') requestModel!: FullAddAdminRequest | AddAdminByLinkRequest

  @Prop({ type: Boolean, default: false, required: false }) hasPermissionSettings!: boolean

  @Prop({ type: String, default: 'add', required: false }) workMode!: 'add' | 'edit'

  @Prop() alertMessage!: string

  deadline = () => new Date().getTime() + 600000

  currentAddType: RequestType = RequestType.Name

  checkAddStatusTimeoutId: number | null = null

  tokenShowTo: string | null = null

  confirmLoading = false

  @Emit()
  resetModel() {
    return null
  }

  @Emit()
  updateUsers(users: Record<number, string>) {
    return users
  }

  get saveButtonTitle() {
    if (this.workMode === "add") {
      return this.$t('add').toString()
    } else {
      return this.$t('save_button').toString()
    }
  }

  get modalTitle() {
    if (this.workMode === "add") {
      return this.$t('add_user_button_title').toString()
    } else {
      const username = 'username' in this.requestModel ? this.requestModel.username : ''

      return this.$t('promote_group_admin_modal_title', [ username ]).toString()
    }
  }

  get tabs(): Array<SelectOptionData> {
    return [
      {
        label: this.$t('add_user_modal_name_tab').toString(),
        value: RequestType.Name,
        icon: {
          name: 'person'
        }
      },
      {
        label: this.$t('add_user_modal_link_tab').toString(),
        value: RequestType.Link,
        icon: {
          name: 'link'
        }
      },
    ]
  }

  isFullAddAdminStructure(model: FullAddAdminRequest | AddAdminByLinkRequest): model is FullAddAdminRequest {
    return 'username' in model
  }

  resetStateToDefault(): void {
    this.resetModel()

    this.modal = false
    this.currentAddType = RequestType.Name
    this.tokenShowTo = null

    if (this.checkAddStatusTimeoutId) {
      clearTimeout(this.checkAddStatusTimeoutId)
      this.checkAddStatusTimeoutId = null
    }
  }

  async checkAddStatus(token: AddAdminByLinkResponse['token']) {
    const { data } = await AddAdminApi.stateAdminByCode('tg', { token })

    if (data.binded && data.users_map && this.checkAddStatusTimeoutId) {
      this.tokenShowTo = null
      clearTimeout(this.checkAddStatusTimeoutId)

      this.updateUsers(data?.users_map ?? {})
    } else {
      this.checkAddStatusTimeoutId = setTimeout(async () => {
        await this.checkAddStatus(token)
      }, 3500)
    }
  }

  setAddAdminType(type: RequestType): void {
    this.currentAddType = type

    if (type === RequestType.Link) {
      this.$delete(this.requestModel, 'username')
    }
    if (type === RequestType.Name) {
      this.$set(this.requestModel, 'username', '')
    }
  }

  handleAdminByLinkResponse(response: AddAdminByLinkResponse): void {
    const { token, link } = response

    this.tokenShowTo = link

    this.checkAddStatus(token)
  }

  makeRequest(): void {
    if (this.currentAddType === RequestType.Name && this.isFullAddAdminStructure(this.requestModel)) {
      this.confirmLoading = true

      AddAdminApi.addAdminByName('tg', this.requestModel)
        .then(({ data }) => this.updateUsers(data?.users_map ?? {}))
        .catch(errorNotification)
        .finally(() => this.confirmLoading = false)
    }

    if (this.currentAddType === RequestType.Link) {
      this.confirmLoading = true

      AddAdminApi.addAdminByLink('tg', this.requestModel)
        .then(({ data }) => this.handleAdminByLinkResponse(data))
        .catch(errorNotification)
        .finally(() => this.confirmLoading = false)
    }
  }
}
